const $ = window.Bliss
import { StickyElement } from "sticky-element"

const SCROLL_DURATION = 1000

// https://gist.github.com/gre/1650294
function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

class Startscreen {
  constructor(el) {
    this.el = el
    this.content = $("#content")
    this.isScrolling = false

    window.scrollTo(0, 0)

    this.el.addEventListener("click", this.listener)

    new StickyElement($("[data-sticky-header]"))
  }

  listener = (e) => {
    e.preventDefault()

    if (!this.isScrolling) {
      this.initScrolling()
      this.removeScrollListener()
    }
  }

  removeListeners() {
    this.el.removeEventListener("click", this.listener)
  }

  initScrolling() {
    this.isScrolling = true
    this.startTimestamp = null

    this.startScrollPosition = window.pageYOffset
    this.endScrollPosition = this.el.offsetHeight
    this.scrollDistance = this.endScrollPosition - this.startScrollPosition

    window.requestAnimationFrame(this.scroll)
  }

  scroll = (timestamp) => {
    if (this.startTimestamp === null) {
      this.startTimestamp = timestamp
    }

    const t = timestamp - this.startTimestamp

    // divide current time by the duration to get a value between 0 and 1
    // and calculate the scroll position with an easeing function
    let nextPosition = easeInOutCubic(t / SCROLL_DURATION)
    // calculating the "real" (pixel) value of the next scroll position
    nextPosition = nextPosition * this.scrollDistance

    window.scrollTo(0, nextPosition + this.startScrollPosition)

    if (t < SCROLL_DURATION) {
      window.requestAnimationFrame(this.scroll)
    } else {
      this.startTimestamp = null
      this.isScrolling = false
    }
  }
}

export default Startscreen
