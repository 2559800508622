const $ = window.Bliss

class FooterContactForm {
  constructor(el) {
    this.el = el

    this.toggle = this.el.querySelector("[data-footer-contact-toggle]")
    this.content = this.el.querySelector("[data-footer-contact-content]")

    this.addListener()
  }

  addListener() {
    this.toggle.addEventListener("click", (e) => {
      e.preventDefault()
      this.showForm()
    })

    this.el.addEventListener("submit", (e) => {
      e.preventDefault()
      this.submitForm()
    })
  }

  showForm() {
    this.el.classList.add("expanded")
    this.content.querySelector('input[type="text"]').focus()
  }

  showValidationError() {
    this.el.classList.add("validation-error")
  }

  hideValidationError() {
    this.el.classList.remove("validation-error")
  }

  showError() {
    this.el.classList.add("error")
  }

  hideError() {
    this.el.classList.remove("error")
  }

  submitForm() {
    const csrftoken = $("input[name=csrfmiddlewaretoken]", this.content)
    const inputField = $("input[type=text]", this.content)

    this.hideValidationError()
    this.hideError()

    if (inputField.value === "") {
      this.showValidationError()
      return false
    }

    const data = encodeURI(`${inputField.name}=${inputField.value}`)

    $.fetch(this.el.action, {
      method: this.el.method,
      data,
      headers: {
        "X-CSRFToken": csrftoken.value,
      },
    })
      .then(() => {
        this.el.classList.add("success")
      })
      .catch((error) => {
        if (error.xhr.status === 400) {
          this.showValidationError()
        } else {
          this.showError()
        }
      })
  }
}

export default FooterContactForm
