import _debounce from "lodash/throttle"

const $ = window.Bliss
const $$ = $.$

class FocusAreaTabs {
  constructor(tabWrapper) {
    this.wrapper = tabWrapper
    this.tablist = $("[data-fa-tab-list]", this.wrapper)
    this.tabs = $$("[data-fa-tab]", this.tablist)
    this.contents = this.tabs.map((tab) => {
      return $(tab.getAttribute("href"))
    })
    this.activeTab = this.tabs[0]

    this.checkScrollPosition()

    this.addListener()
  }

  addListener() {
    this.tabs.forEach((tab) => {
      tab.addEventListener("click", (e) => {
        e.preventDefault()
        this.openTab(e.currentTarget)
      })
    })

    this.tablist.addEventListener(
      "scroll",
      _debounce(() => {
        this.checkScrollPosition()
      }, 300),
    )
  }

  openTab(tab) {
    const tabIndex = this.tabs.indexOf(tab)

    if (tabIndex > -1) {
      this.closeActiveTab()

      this.activeTab = tab
      this.activeTab.classList.add("active")
      this.contents[tabIndex].classList.add("active")
    }
  }

  closeActiveTab() {
    const tabIndex = this.tabs.indexOf(this.activeTab)
    this.activeTab.classList.remove("active")
    this.activeTab = null
    this.contents[tabIndex].classList.remove("active")
  }

  checkScrollPosition() {
    const hideLeftIndicator = this.tablist.scrollLeft <= 15
    const hideRightIndicator =
      this.tablist.scrollLeft >=
      this.tablist.scrollWidth - this.tablist.clientWidth - 15

    if (hideLeftIndicator) {
      this.wrapper.classList.remove("scroll-left")
    } else {
      this.wrapper.classList.add("scroll-left")
    }

    if (hideRightIndicator) {
      this.wrapper.classList.remove("scroll-right")
    } else {
      this.wrapper.classList.add("scroll-right")
    }
  }
}

export default FocusAreaTabs
